import { useLocalizedCurrency } from '~/composeables/useLocalizedCurrency';
import NP from 'number-precision';
import { useCartStore } from '~/store/cart';
import { useUserStore } from '~/store/user';
import { CampaignItem } from '~/constants/types/multiDiscount';

/**
 * This composable is designed to accept either a ProductVariantModel or a CartApiItem
 * @param props - please note that the props from component can't be changed, you must add the entire props or it will loose reactivity
 */
export default function useMultiDiscount(props: any) {
  type ThisFlag = {
    code: string;
    text: string;
    type: string;
    background: null;
    groupName: string;
    groupCode: string;
    description: string;
  }

  const cartStore = useCartStore();
  const userStore = useUserStore();
  const { $t, $toNumber } = useNuxtApp();

  const percentFlags = computed<ThisFlag[]>(()=> {
    if (props.activeVariant) {
      // created from productVariant
      return props.activeVariant.percentageCampaigns as ThisFlag[];
    } else {
      // created from CartApiItem
      return props.item.flags.filter((f: ThisFlag)=> f.groupCode === 'percentageCampaigns' || f.groupCode === 'percentageCampaignsMixedProducts') as ThisFlag[];
    }
  });
  const stepFlag = computed<ThisFlag | undefined>(()=> {
    if (props.activeVariant) {
      // created from productVariant
      return props.activeVariant.stepCampaigns as ThisFlag | undefined;
    } else {
      // created from CartApiItem
      return props.item.flags.find((f: ThisFlag)=> f.groupCode === 'stepCampaigns') as ThisFlag | undefined;
    }
  });

  const giftFlag = computed<ThisFlag | undefined>(()=> {
    if (props.activeVariant) {
      // created from productVariant
      return props.activeVariant.giftCampaigns as ThisFlag | undefined;
    } else {
      // created from CartApiItem
      return props.item.flags.find((f: ThisFlag)=> f.groupCode === 'giftCampaigns') as ThisFlag | undefined;
    }
  });

  const partNo = computed<string>(()=> {
    if (props.activeVariant) {
      // created from productVariant
      return props.activeVariant.partNo;
    } else {
      // created from CartApiItem
      return props.item.partNo;
    }
  });

  const stepCampaigns = computed<CampaignItem[]>(()=> {
    if (!stepFlag.value) {
      return [];
    }
    const price = userStore.getPrice(partNo.value, false);
    const output = [] as CampaignItem[];

    const steps = stepFlag.value.code.split('-');

    steps.forEach((step, index) => {
      const values = step.split('get').map((m) => parseInt(m));
      let savings = '';
      try {
        if (price.status === 'ok' && price.price?.priceBeforeDiscountBeforeVat) {
          const userPrice = $toNumber(price.price.priceBeforeDiscountBeforeVat);

          savings = $t('buyMore.perItem', {
            sum: useLocalizedCurrency(NP.times((userPrice * values[0]) / (values[0] + values[1]))),
          });
        }

        const thisReached = cartStore.getQuantityFromPartNo(partNo.value) >= values[0];

        if (thisReached) {
          // reset the one before
          if (index > 0 && output[index - 1]) {
            output[index - 1].reached = false;
          }
        }

        output.push({
          text: $t('buyMore.steps', { buy: values[0], get: values[1] }),
          savings,
          limit: values[0],
          gainedAmount: values[1],
          reached: thisReached,
          description: stepFlag.value?.description || '',
        });

      } catch (e) {
        console.log(e);
      }

    });

    return output.sort((a, b) => a.limit > b.limit ? 1:-1);
  });

  const giftCampaigns = computed<{
    text: string,
    description: string,
  }[]>(()=> {
    if (!giftFlag.value) {
      return [];
    }

    return [{
      text: giftFlag.value.text,
      description: giftFlag.value.description,
    }];
  });

  const nextStep = computed<CampaignItem | null>(()=> {
    let output = null as CampaignItem | null;
    for (const step of stepCampaigns.value) {
      if (cartStore.getQuantityFromPartNo(partNo.value) < step.limit) {
        output = step;
        break;
      }
    }
    return output;
  });

  const percentageCampaigns = computed<CampaignItem[]>(()=> {
    if (!userStore.isLoggedIn) {
      return [];
    }

    if (!percentFlags.value.length) {
      return [];
    }
    const output = [] as CampaignItem[];
    const price = userStore.getPrice(partNo.value, false);

    percentFlags.value.forEach((flag, index) => {
      const values = flag.code.split('get');
      if (values.length !== 2) {
        console.error('percentageCampaigns not correct formated');
      } else {
        let savings = '';
        if (price.status === 'ok' && price.price?.priceBeforeDiscountBeforeVat) {
          try {
            const userPrice = $toNumber(price.price.priceBeforeDiscountBeforeVat);
            const percent = (100 - parseInt(values[1])) / 100;
            const sum = Math.floor(NP.times(userPrice, parseInt(values[0]), percent) / parseInt(values[0]));
            savings = $t('buyMore.perItem', {
              sum: useLocalizedCurrency(sum, true),
            });
          } catch (e) {
            console.log(e);
          }
        }

        const thisReached = cartStore.getQuantityFromPartNo(partNo.value) >= parseInt(values[0]);
        if (thisReached) {
          // reset the one before
          if (index > 0 && output[index - 1]) {
            output[index - 1].reached = false;
          }
        }

        output.push( {
          text: flag.text || '',
          savings,
          limit: parseInt(values[0]),
          gainedAmount: parseInt(values[1]),
          reached: thisReached,
          description: flag.description || '',
        });

      }
    });
    return output.sort((a, b) => a.limit > b.limit ? 1:-1);
  });

  const nextPercent = computed<CampaignItem | null>(()=> {
    let output = null as CampaignItem | null;
    for (const step of percentageCampaigns.value) {
      if (cartStore.getQuantityFromPartNo(partNo.value) < step.limit) {
        output = step;
        break;
      }
    }
    return output;
  });

  const hasCampaign = computed<boolean>(()=> { return stepCampaigns.value.length > 0 || percentageCampaigns.value.length > 0 || giftCampaigns.value.length > 0; }).value;

  return {
    stepCampaigns,
    percentageCampaigns,
    nextStep,
    nextPercent,
    giftCampaigns,
    hasCampaign,
  };
}
